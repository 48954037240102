import React from 'react';

import { NewsItem } from '@lerna-monorepo/common/interfaces/news.types';
import { BigTextHeader } from '@lerna-monorepo/common/components/bigTextHeader/bigTextHeader.component';
import { LanguageSlug } from '@lerna-monorepo/common/enums/languageSlug.enum';

import { NewsListPageData } from './newsList.types';
import { LocalLinkSection } from './newsList.styled';

const NewsListPage: React.FC<NewsListPageData> = (
  pageData: NewsListPageData
) => {
  const { slug, data, news, currentLanguage } = pageData.pageContext;
  const { header, listHeader } = data.newsListSection;
  const langPrefix =
    currentLanguage === LanguageSlug.Pl ? '' : `/${currentLanguage}`;
  const newsList = news
    .filter((newsItem: NewsItem) => newsItem.language.slug === currentLanguage)
    .map((newsItem: NewsItem) => ({
      post_title: newsItem.title,
      post_name: `${langPrefix}/${slug}/${newsItem.slug}`,
    }));

  return (
    <>
      <BigTextHeader content={header} />
      <LocalLinkSection header={listHeader} linkBoxes={newsList} />
    </>
  );
};

export default NewsListPage;
