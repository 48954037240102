import styled from 'styled-components';

import { LinkSection } from '@lerna-monorepo/common/components/linkSection/linkSection.component';

export const DynamicContentComponentContainer = styled.div`
  margin: 100px 0 150px;
`;

export const LocalLinkSection = styled(LinkSection)`
  padding-top: 100px;
  padding-bottom: 150px;
`;
